import { Route, Routes, useLocation } from "react-router-dom"
import { ColorModeContext, useMode } from "../../theme.js"
import { CssBaseline, ThemeProvider, useMediaQuery } from "@mui/material"
import '../../index.css'
import { useEffect, useReducer } from "react"
import Menu from "../Menu/index.jsx"
import MobileMenu from "../MobileMenu.jsx"
import Footer from "../Footer.jsx"
import Home from '../../scenes/home/index.jsx'
import OurStory from '../../scenes/our-story/index.jsx'
import ManagedServices from '../../scenes/services/managed-it-services.jsx'
import NetworkCyberSecurity from "../../scenes/services/network-and-cybersecurity.jsx"
import Support from "../../scenes/support/index.jsx"
import Contact from "../../scenes/contact/index.jsx"
import OurMission from "../../scenes/our-mission/index.jsx"
import Careers from "../../scenes/careers/index.jsx"
import ITSolutions from "../../scenes/services/it-solutions.jsx"
import PageNotFound from "../PageNotFound/index.jsx"

const App = () => {

  const [theme, colorMode] = useMode()
  const isDesktop = useMediaQuery(theme.breakpoints.up('laptop'))
  
  const [formState, dispatch] = useReducer(setState, '')

  const setFormState = (type) => {
    dispatch({type})
  }

  const { pathname } = useLocation()

  useEffect(() => {
    if (pathname !== '/contact') {
      setFormState('')
    }
  }, [pathname])

  return (
    <ColorModeContext.Provider value={colorMode}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="app">
        <div id="back-to-top-anchor"></div>
        {isDesktop ? <Menu onChangeFormState={setFormState} /> : <MobileMenu onChangeFormState={setFormState} />}
        <main id='main' className="content">
          <Routes>
            {/* GENERAL */}
            <Route path="/" element={<Home />} />

            {/* COMPANY */}
            <Route path="/our-mission" element={<OurMission />} />
            <Route path="/our-story" element={<OurStory />} />
            <Route path="/contact" element={<Contact formState={formState} onChangeFormState={setFormState} />} />
            <Route path="/careers" element={<Careers />} />

            {/* SUPPORT */}
            <Route path="/support" element={<Support />} />

            {/* SERVICES */}
            <Route path="/it-solutions" element={<ITSolutions />} />
            <Route path="/managed-it-services" element={<ManagedServices />} />
            <Route path="/network-and-cybersecurity" element={<NetworkCyberSecurity />} />
            <Route path="/404" element={ <PageNotFound location={pathname} /> } />
            <Route path="*" element={ <PageNotFound location={pathname} />} />
          </Routes>
          <Footer />
        </main>
      </div>
    </ThemeProvider>
    </ColorModeContext.Provider>
  )
}

const setState = (formState, action) => {
  if (action.type === 'error') {
    return 'Error'
  } else if (action.type === 'confirm') {
    return 'Confirm'
  } else {
    return ''
  }
}

export default App
