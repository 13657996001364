import { Typography, useMediaQuery, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

const PageNotFound = ({ location }) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('laptop'))
  const isPhone = useMediaQuery(theme.breakpoints.down('tablet'))
  const navigate = useNavigate()

  const [pathname] = useState(location)

  useEffect(() => {
    if (!sessionStorage.getItem('navigated')) {
      sessionStorage.setItem('navigated', 'true')
      navigate('/404')
    }
  },[navigate])

  return (
    <div className='page' style={{
      display:"flex",
      flexDirection:"column",
      overflow:"hidden"
    }}>
      <div style={{display:"flex", flexDirection:"column", textAlign:isPhone ? "left" : "center"}}>
        <div style={{margin:isDesktop ? "" : "1rem 2rem"}}>
          <h1>404</h1>
        </div>
        <div style={{margin:isDesktop ? "2rem 3rem" : "2rem", textAlign:isPhone ? "left" : "center"}}>
          <Typography variant="p" fontSize={22} fontWeight="normal">
           The requested url {pathname} was not found.
          </Typography>
        </div>
      </div>
    </div>
  )
}

PageNotFound.propTypes = {
  location: PropTypes.string.isRequired
}

export default PageNotFound